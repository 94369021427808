if( $('.today').length > 0 || $('.v2').length > 0) {

    document.addEventListener('DOMContentLoaded', function() {
        const deadline = new Date($yearwp, $monthwp, $daywp, $hourswp, $minuteswp);
        
        console.log(deadline);
        let timerId = null; 
        function declensionNum(num, words) {
            return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? num % 10 : 5]];
        }
        // Home timer
        function countdownTimer() {
            const diff = deadline - new Date();
            if (diff <= 0) { 
                clearInterval(timerId);
            }
            const days = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0;
            const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
            const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
            $days.textContent = days < 10 ? '0' + days : days;
            $hours.textContent = hours < 10 ? '0' + hours : hours;
            $minutes.textContent = minutes < 10 ? '0' + minutes : minutes;

            $days.dataset.title = declensionNum(days, ['days', 'days', 'days']);
            $hours.dataset.title = declensionNum(hours, ['hours', 'hours', 'hours']);
            $minutes.dataset.title = declensionNum(minutes, ['minutes', 'minutes', 'minutes']);
        }
        const $days = document.querySelector('.card__timer--day div');
        const $hours = document.querySelector('.card__timer--hours div');
        const $minutes = document.querySelector('.card__timer--minutes div');
        
        countdownTimer();
        timerId = setInterval(countdownTimer, 1000);

    });
}

if( $('.main').length > 0) {
    var startTime = new Date().getTime();
    var countdown = setInterval(function() {

        var currentTime = new Date().getTime();
        var timeDiff = currentTime - startTime;
        var remainingSeconds = 300 - Math.floor(timeDiff / 1000);
        if (remainingSeconds <= 0) {
            clearInterval(countdown);
            console.log("Time: 00:00:00");
        } else {
            var remainingMinutes = Math.floor(remainingSeconds / 60);
            var remainingHours = Math.floor(remainingMinutes / 60);
            var remainingDays = Math.floor(remainingHours / 24);
            var displaySeconds = remainingSeconds % 60;
            var displayMinutes = remainingMinutes % 60;
            var displayHours = remainingHours % 24;
            var displayDays = remainingDays;

            document.querySelector('.card__timer--minutes div').textContent = displaySeconds;
            document.querySelector('.card__timer--hours div').textContent = displayMinutes;
            document.querySelector('.card__timer--day div').textContent = displayHours;
        }

    }, 1000);
}